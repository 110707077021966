import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';

//import dayjs from 'dayjs';
import TabelaResultados from './TabelaResultados';
import api from '../../../../api/api';
import { toast } from 'react-toastify';
import { useAuthState } from '../../../../contexts/AuthContext';
import { useConfirm } from 'material-ui-confirm';
const LinhaProducaoTelaGrande = () => {
  const [loading, setLoading] = useState(false);
  const [listaSemanal, setListaSemanal] = useState([]);
  const [atualizaFiltro, setAtualizaFiltro] = useState(false);
  const { empresaAtiva, maquinaCodigo, usuarioIDLogado, codigoFuncionario } =
    useAuthState();

  const maquinaDescricao = localStorage.getItem('maquinaDescricao');
  const confirm = useConfirm();
  useEffect(() => {
    setLoading(true);
    buscarDados();
  }, [atualizaFiltro]);

  const buscarDados = async () => {
    try {
      const { data } = await api.post(
        '/producao/listaImpressora',
        { setor: maquinaDescricao },
        {
          headers: { banco: empresaAtiva },
        }
      );

      setListaSemanal(data);
      console.log(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const concluirEtapa = (producao, qtde, maquina) => {
    if (!qtde || qtde <= 0) {
      toast.error('Você deve preencher uma quantidade valida');
    } else {
      confirm({
        title: 'Confirme a Operação',
        description: `DESEJA CONCLUIR A ETAPA ${
          maquina ? `'E ENCAMINHAR PARA A MAQUINA' ${maquina}` : ''
        }`,
        confirmationText: 'SIM',
        cancellationText: 'NÃO',
      })
        .then(() => {
          setLoading(true);
          api
            .put(
              '/producao/finalizar/etapa',
              {
                producao: producao,
                maquina: maquinaCodigo,
                quantidade: qtde,
                usuario: usuarioIDLogado,
                codigoFuncionario: codigoFuncionario,
              },
              {
                headers: { banco: empresaAtiva },
              }
            )
            .then(() => {
              toast.success('Producão alterada com sucesso ');
              setAtualizaFiltro(!atualizaFiltro);
            })
            .catch((err) => {
              toast.success('Erro ao  alterar Producao ');
              console.log(err);
              setAtualizaFiltro(!atualizaFiltro);
            })
            .finally(() => setLoading(false));
        })
        .catch(() => {
          /* ... */
        });
    }
  };

  function encontrarProximaMaquina(dados) {
    const maquinas = [
      { nome: dados.maquina1, id: dados.idMaquina1 },
      { nome: dados.maquina2, id: dados.idMaquina2 },
      { nome: dados.maquina3, id: dados.idMaquina3 },
      { nome: dados.maquina4, id: dados.idMaquina4 },
    ];

    const maquinasValidas = maquinas.filter(
      (maquina) => maquina.nome && maquina.id
    );

    const indexAtual = maquinasValidas.findIndex(
      (maquina) => maquina.id === maquinaCodigo
    );

    if (indexAtual !== -1 && indexAtual + 1 < maquinasValidas.length) {
      return maquinasValidas[indexAtual + 1];
    } else {
      return null; // Nenhuma próxima máquina encontrada
    }
  }

  if (loading) {
    return (
      <Box
        sx={{
          background: '#464758',
          width: '100dvw',
          display: 'flex',
          height: '100dvh',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    );
  }
  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        height: '100%',
        background: (theme) => theme.palette.primary.main,
        maxWidth: { lg: '100dvw', xl: '100dvw' },
      }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: { lg: '90dvh', xl: '90dvh' },
          maxHeight: { lg: '90dvh', xl: '90dvh' },
        }}
      >
        <TabelaResultados
          listaSemanal={listaSemanal}
          concluirEtapa={concluirEtapa}
          buscarDados={buscarDados}
          encontrarProximaMaquina={encontrarProximaMaquina}
        />
      </Grid>
    </Grid>
  );
};

export default LinhaProducaoTelaGrande;
